import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { isAddress } from 'utils'
import { useAppDispatch } from 'state'
import { getAchievements } from 'state/achievements/helpers'
import { FetchStatus } from 'config/constants/types'
import useSWRImmutable from 'swr/immutable'
import { State, ProfileState } from '../types'
import { fetchProfile, fetchProfileAvatar, fetchProfileUsername } from '.'
import { getProfile } from './helpers'
import jwt from 'jsonwebtoken';
import useAuth from 'hooks/useAuth'


export const useFetchProfile = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchProfile(account))
    }
  }, [account, dispatch])
}

export const useProfileForAddress = (address: string) => {
  const { data, status, mutate } = useSWRImmutable(address ? [address, 'profile'] : null, () => getProfile(address))

  return {
    profile: data,
    isFetching: status === FetchStatus.Fetching,
    refresh: mutate,
  }
}

export const useAchievementsForAddress = (address: string) => {
  const { data, status, mutate } = useSWRImmutable(address ? [address, 'achievements'] : null, () =>
    getAchievements(address),
  )

  return {
    achievements: data || [],
    isFetching: status === FetchStatus.Fetching,
    refresh: mutate,
  }
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  const { session, loading } = useAuth()

  // Check for jwt
  const userToken = localStorage.getItem('userToken');

  if(userToken){
    // Decode JWT token to get expiration time
    const decodedToken = jwt.decode(userToken);
    let user = decodedToken
    const expirationTime = user.exp * 1000;
    // // Check if token is expired
    if (Date.now() >= expirationTime) {
      // Token is expired, handle accordingly
      localStorage.removeItem('userToken');
      return { hasRegistered: false, profile: null }
    }

    return { profile: {jwt: user} }
  }else if(session){
    return { profile: {jwt: session.user} }
  }

  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading }
}

export const useGetProfileAvatar = (account: string) => {
  const profileAvatar = useSelector((state: State) => state.profile.profileAvatars[account])
  const { username, nft, hasRegistered, usernameFetchStatus, avatarFetchStatus } = profileAvatar || {}
  const dispatch = useAppDispatch()

  useEffect(() => {
    const address = isAddress(account)

    if (!nft && avatarFetchStatus !== FetchStatus.Fetched && address) {
      dispatch(fetchProfileAvatar(account))
    }

    if (
      !username &&
      avatarFetchStatus === FetchStatus.Fetched &&
      usernameFetchStatus !== FetchStatus.Fetched &&
      address
    ) {
      dispatch(fetchProfileUsername({ account, hasRegistered }))
    }
  }, [account, nft, username, hasRegistered, avatarFetchStatus, usernameFetchStatus, dispatch])

  return { username, nft, usernameFetchStatus, avatarFetchStatus }
}
