import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@thenexlabs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Jetbrains Mono';
  }
  body {
    background-color: ${({ theme }) => theme.colors.backgroundAlt};

    img {
      height: auto;
      max-width: 100%;
    }

    overflow-x: hidden; //////// added for landing page XpBanner element

    // cursor: none; // added for cursor glowing dot override
  }
  .scene-container {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(1);
  }
  // react-chat-widget
  .rcw-launcher {
    background-color: #11bf0d;
  }
  .rcw-conversation-container > .rcw-header {
    background-color: #11bf0d;
    color: black;
  }

  .rcw-messages-container {
    background-color: black;
  }
  
  // cursor styling
  // #cursor-dot {
  //   z-index: 999999999;
  //   position: absolute;
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 50%;
  //   background-color: white;
  //   pointer-events: none;
  //   transition: ease .015s;
  //   display: none;
  //   box-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00, 0 0 40px #00ff00;
  // }
  // #cursor-diamond {
  //   z-index: 99999999;
  //   position: absolute;
  //   width: 7px;
  //   height: 7px;
  //   background-color: black;
  //   pointer-events: none;
  //   cursor: none;
  //   display: none;
  //   transition: ease .35s;
  //   box-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
  //   transform: rotate(45deg);
  // }
  // #cursor-square {
  //   // z-index: 99999999;
  //   // position: absolute;
  //   // width: 5px;
  //   // height: 5px;
  //   // background-color: black;
  //   // pointer-events: none;
  //   // cursor: none;
  //   // display: none;
  //   // transition: ease .3s;
  //   // box-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
  // }
  // #cursor-circle {
  //   z-index: 99999999;
  //   position: absolute;
  //   width: 6px;
  //   height: 6px;
  //   background-color: black;
  //   pointer-events: none;
  //   cursor: none;
  //   display: none;
  //   transition: ease .3s;
  //   box-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
  //   border-radius: 50%;
  // }

`

export default GlobalStyle
