import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicXp } from './fetchXp'
import { fetchUserLockedXp } from './fetchXpUser'


import { SerializedXpState, SerializedXp, SerializedXpUserData } from '../types'

const noDataXpConfig = {
  xpCap: '0',
  totalLockedXp: '0',
  currentSupply: '0'
}

const noAccountXpConfig = {
  lockedXp: (0*10e18).toString(),
  unlockableXp: '0'
}

const initialState: SerializedXpState = {
  userData: noAccountXpConfig,
  data: noDataXpConfig,
  loadArchivedXpData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchXpPublicDataAsync = createAsyncThunk<
  SerializedXp,
  string,
  {
    state: AppState
  }
>(
  'xp/fetchXpPublicDataAsync',
  async ( x ) => {

    const payload = await fetchPublicXp()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { xp } = getState()
      if (xp.loadingKeys[stringify({ type: fetchXpPublicDataAsync.typePrefix, arg })]) {
        console.debug('xp action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

// interface XpUserDataResponse {
//   pid: number
//   allowance: string
//   tokenBalance: string
//   stakedBalance: string
//   earnings: string
// }
//
export const fetchXpUserDataAsync = createAsyncThunk<
  SerializedXpUserData,
  string,
  {
    state: AppState
  }
>(
  'xp/fetchXpUserDataAsync',
  async ( account ) => {

    const payload = await fetchUserLockedXp(account)

    // var serializedXpData: SerializedXp = {
    //   lockedXp: payload.lockedXp,
    //   unlockableXp: payload.unlockableXp
    // }

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { xp } = getState()
      if (xp.loadingKeys[stringify({ type: fetchXpUserDataAsync.typePrefix, arg })]) {
        console.debug('xp user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const xpSlice = createSlice({
  name: 'Xp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update xp with live data
    builder.addCase(fetchXpPublicDataAsync.fulfilled, (state, action) => {
      state.data.xpCap = action.payload.xpCap
      state.data.totalLockedXp = action.payload.totalLockedXp
      state.data.currentSupply = action.payload.currentSupply
    })

    builder.addCase(fetchXpUserDataAsync.fulfilled, (state, action) => {
      state.userData.lockedXp = action.payload.lockedXp
      state.userData.unlockableXp = action.payload.unlockableXp
    })

  },
})

export default xpSlice.reducer
