import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import dataSticksABI from 'config/abi/dataSticks.json'
import multicall from 'utils/multicall'
import { getDataSticksAddress } from 'utils/addressHelpers'


export const fetchUserDataSticks = async (account: string) => {
  const dataSticksAddress = getDataSticksAddress()
  const calls = []

  let id = 1
  let hasMoreDataSticks = true

  while (hasMoreDataSticks) {
    // Check if dataStick ID exists by checking if its totalSupply is greater than 0
    const totalSupplyCall = {
      address: dataSticksAddress,
      name: 'totalSupply',
      params: [id]
    }
    const totalSupplyResponse = await multicall<BigNumber[]>(dataSticksABI, [totalSupplyCall])
    const totalSupply = new BigNumber(totalSupplyResponse[0]).toNumber()

    if (totalSupply > 0) {
      const callUserMinted = {
        address: dataSticksAddress,
        name: 'userMintedMap',
        params: [account, id]
      }

      const callBalance = {
        address: dataSticksAddress,
        name: 'balanceOf',
        params: [account, id]
      }

      calls.push(callUserMinted)
      calls.push(callBalance)
    } else {
      hasMoreDataSticks = false
    }

    id++
  }

  const rawDataSticksAmounts = await multicall<BigNumber[]>(dataSticksABI, calls)

  const parsedAmounts = rawDataSticksAmounts.map((dataSticksBalance) => {
    return new BigNumber(dataSticksBalance).toJSON()
  })

  const dataSticksById = {}

  for (let i = 0; i < id; i++) {
    dataSticksById[i+1] = {
      numMinted: parsedAmounts[i * 2],
      balance: parsedAmounts[i * 2 + 1]
    }
  }

  return { 
    dataSticks: dataSticksById 
  }
}

