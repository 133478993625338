import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'TheNEX',
  description:
    'The most innovative GameFi project on any network! Earn XP through yield farming or win it in the NEX Hub, then stake it in Boost Pools to earn more tokens! Initial Farm Offerings, NFTs, and more, on a platform you can trust.',
  image: 'https://thenex.world/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('TheNEX')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('TheNEX')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('TheNEX')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('TheNEX')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('TheNEX')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('TheNEX')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('TheNEX')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('TheNEX')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('TheNEX')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('TheNEX')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('TheNEX')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('TheNEX')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('TheNEX')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('TheNEX')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('TheNEX')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('TheNEX')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('TheNEX')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('TheNEX')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('TheNEX Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('TheNEX Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('TheNEX Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('TheNEX')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('TheNEX')}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Activity')} | ${t('TheNEX')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Profile')} | ${t('TheNEX')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('TheNEX')}`,
      }
    default:
      return null
  }
}
