import tokens from 'config/constants/tokens'
import { getXpVaultAddress, getIgoPoolAddress, getMasterGamerAddress } from 'utils/addressHelpers'

const xpLpAddress = '0x55B45b2bf0895A2394627f303F6118E8A4f1D193'

const XpBalanceStrategy = {
  name: 'erc20-balance-of',
  params: {
    address: tokens.xp.address,
    decimals: 0,
    symbol: tokens.xp.symbol,
  },
}

const XpVaultSharesStrategy = {
  name: 'contract-call',
  params: {
    address: getXpVaultAddress(),
    decimals: 0,
    output: 'shares',
    methodABI: {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'userInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: 'shares',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'lastDepositedTime',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'xpAtLastUserAction',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'lastUserActionTime',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const XpVaultPricePerFullShareStrategy = {
  name: 'contract-call',
  params: {
    address: getXpVaultAddress(),
    decimals: 0,
    args: [],
    methodABI: {
      inputs: [],
      name: 'getPricePerFullShare',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const IFOPoolSharesStrategy = {
  name: 'contract-call',
  params: {
    address: getIgoPoolAddress(),
    decimals: 0,
    output: 'shares',
    methodABI: {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'userInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: 'shares',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'lastDepositedTime',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'xpAtLastUserAction',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'lastUserActionTime',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const IFOPoolPricePerFullShareStrategy = {
  name: 'contract-call',
  params: {
    address: getIgoPoolAddress(),
    decimals: 0,
    args: [],
    methodABI: {
      inputs: [],
      name: 'getPricePerFullShare',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const UserStakeInXpPoolStrategy = {
  name: 'contract-call',
  params: {
    address: getMasterGamerAddress(),
    decimals: 0,
    args: [0, '%{address}'],
    output: 'amount',
    methodABI: {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'userInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'rewardDebt',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const XpBnbLpTotalSupplyStrategy = {
  name: 'contract-call',
  params: {
    address: xpLpAddress,
    decimals: 0,
    args: [],
    methodABI: {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const XpBnbLpReserve0Strategy = {
  name: 'contract-call',
  params: {
    address: xpLpAddress,
    decimals: 0,
    args: [],
    output: '_reserve0',
    methodABI: {
      constant: true,
      inputs: [],
      name: 'getReserves',
      outputs: [
        {
          internalType: 'uint112',
          name: '_reserve0',
          type: 'uint112',
        },
        {
          internalType: 'uint112',
          name: '_reserve1',
          type: 'uint112',
        },
        {
          internalType: 'uint32',
          name: '_blockTimestampLast',
          type: 'uint32',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  },
}

const XpBnbLpXpBnbBalanceStrategy = {
  name: 'contract-call',
  params: {
    address: getMasterGamerAddress(),
    decimals: 0,
    args: [251, '%{address}'],
    output: 'amount',
    methodABI: {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'userInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'rewardDebt',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  },
}

function createPoolStrategy(poolAddress) {
  return {
    name: 'contract-call',
    params: {
      address: poolAddress,
      decimals: 0,
      output: 'amount',
      methodABI: {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'userInfo',
        outputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rewardDebt',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    },
  }
}

export { createPoolStrategy }

export const snapshotStrategies = [
  XpBalanceStrategy,
  XpVaultSharesStrategy,
  XpVaultPricePerFullShareStrategy,
  IFOPoolSharesStrategy,
  IFOPoolPricePerFullShareStrategy,
  UserStakeInXpPoolStrategy,
  XpBnbLpTotalSupplyStrategy,
  XpBnbLpReserve0Strategy,
  XpBnbLpXpBnbBalanceStrategy,
]
