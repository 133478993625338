import { PoolDeployedBlockNumber } from './types'

const poolsDeployedBlockNumber: PoolDeployedBlockNumber = {
  "0x55B45b2bf0895A2394627f303F6118E8A4f1D193": 23890900,
  "0x8a60E295e9E817244f112E65b44966fF7D0951c0": 23890900,
  "0xf8c895EDaB3E9cF513B22769254C89C4f20C96fe": 23890900,
  "0x80FDF6a2FC8945aE1f2c3d45d32FeAeF99A18CD7": 23890900,
  "0x74bA5996E7461c63B4Cf63f47A571605D2e13651": 23890900

}

export default poolsDeployedBlockNumber
