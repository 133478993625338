import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicAutoBossBattles } from './fetchAutoBossBattles'
import { fetchUserAutoBossBattles } from './fetchAutoBossBattlesUser'
import {
  SerializedAutoBossBattlesState,
  SerializedAutoBossBattles,
  SerializedAutoBossBattlesUserData
} from '../types'


const noDataAutoBossBattlesConfig = { // public
  battleCounter: '0',
  drops: []
}

const noAccountAutoBossBattlesConfig = { // user
  battle: {
    bossId: '0',
    startTime: '0',
    battleDuration: '0',
    feeAmount: '0'
  },
  kills: '0',
  deaths: '0',
  // dailyRewardsEarned: '0',
  // lastTimeEarned: '0',
  // totalXpEarned: '0'
}

const initialState: SerializedAutoBossBattlesState = {
  userData: noAccountAutoBossBattlesConfig,
  data: noDataAutoBossBattlesConfig,
  loadArchivedAutoBossBattlesData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchAutoBossBattlesPublicDataAsync = createAsyncThunk<
  SerializedAutoBossBattles,
  string,
  {
    state: AppState
  }
>(
  'autoBossBattles/fetchAutoBossBattlesPublicDataAsync',
  async ( x ) => {

    const payload = await fetchPublicAutoBossBattles()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { autoBossBattles } = getState()
      if (autoBossBattles.loadingKeys[stringify({ type: fetchAutoBossBattlesPublicDataAsync.typePrefix, arg })]) {
        console.debug('autoBossBattles action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)


export const fetchAutoBossBattlesUserDataAsync = createAsyncThunk<
  SerializedAutoBossBattlesUserData,
  string,
  {
    state: AppState
  }
>(
  'autoBossBattles/fetchAutoBossBattlesUserDataAsync',
  async ( account ) => {

    const payload = await fetchUserAutoBossBattles(account)

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { autoBossBattles } = getState()
      if (autoBossBattles.loadingKeys[stringify({ type: fetchAutoBossBattlesUserDataAsync.typePrefix, arg })]) {
        console.debug('autoBossBattles user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const autoBossBattlesSlice = createSlice({
  name: 'AutoBossBattles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update autoBossBattles with live data
    builder.addCase(fetchAutoBossBattlesPublicDataAsync.fulfilled, (state, action) => {
      state.data.battleCounter = action.payload.battleCounter
      state.data.drops = action.payload.drops
    })

    builder.addCase(fetchAutoBossBattlesUserDataAsync.fulfilled, (state, action) => {
      state.userData.battle = action.payload.battle
      state.userData.kills = action.payload.kills
      state.userData.deaths = action.payload.deaths
      // state.userData.dailyRewardsEarned = action.payload.dailyRewardsEarned
      // state.userData.lastTimeEarned = action.payload.lastTimeEarned
      // state.userData.totalXpEarned = action.payload.totalXpEarned
    })
  },
})

export default autoBossBattlesSlice.reducer
