import tokens from './tokens'
import { SerializedFarmConfig } from './types'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterGamer LPs (farms.ts).
   * This list is added to the MasterGamerLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: 3,
    lpSymbol: 'USDC-AVAX LP',
    lpAddresses: {
      1666600000: '0x8842Ec5bE47881093Fe6dAdF6fD4F91d278446F2',
      1666700000: '',
      43113: '0x6438dca1268333ff84310762d51b7154bECE882E',
      43114: '0xa6d14FA4040951D97b34251b819bBB9274987a82'
    },
    token: tokens.usdc,
    quoteToken: tokens.wavax,
  },
]

export default priceHelperLps
