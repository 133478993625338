import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useXpContract } from 'hooks/useContract'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchXpPublicDataAsync, fetchXpUserDataAsync } from '.'
import { DeserializedXp, DeserializedXpUserData, DeserializedXpState, SerializedXp, SerializedXpUserData, State } from '../types'

const deserializeXpData = (data: SerializedXp): DeserializedXp => {
  return {
    xpCap: data.xpCap ? new BigNumber(data.xpCap) : BIG_ZERO,
    totalLockedXp: data.totalLockedXp ? new BigNumber(data.totalLockedXp) : BIG_ZERO,
    currentSupply: data.currentSupply ? new BigNumber(data.currentSupply) : BIG_ZERO
  }
}

const deserializeXpUserData = (data: SerializedXpUserData): DeserializedXpUserData => {
  return {
    lockedXp: data.lockedXp ? new BigNumber(data.lockedXp) : BIG_ZERO,
    unlockableXp: data.unlockableXp ? new BigNumber(data.unlockableXp) : BIG_ZERO
  }
}

export const useXp = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchXpPublicDataAsync(''))
    dispatch(fetchXpUserDataAsync(account))
  }, [account, dispatch])
}

export const useXpData = (): DeserializedXp => {
  const xpData = useSelector((state: State) => state.xp.data)

  if(!xpData){
    return {
      xpCap: new BigNumber('0'),
      totalLockedXp: new BigNumber('0'),
      currentSupply: new BigNumber('0'),
    }
  }

  const deserializedXpData: DeserializedXp = deserializeXpData(xpData)

  return deserializeXpData(xpData)
}

export const useXpUserData = (): DeserializedXpUserData => {
  const xpUserData = useSelector((state: State) => state.xp.userData)

  if(!xpUserData){
    return {
      lockedXp: new BigNumber('0'),
      unlockableXp: new BigNumber('0')
    }
  }

  const deserializedXpUserData: DeserializedXpUserData = deserializeXpUserData(xpUserData)

  return deserializeXpUserData(xpUserData)
}
