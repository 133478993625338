const NEXDEX_EXTENDED = 'https://tokens.thenex.world/nex-extended.json'
const NEXDEX_TOP100 = 'https://tokens.thenex.world/nex-top-100.json'


export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  //...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
