import { BigNumber } from '@ethersproject/bignumber'
import { Campaign, TranslatableText } from 'config/constants/types'
import igosList from 'config/constants/igo'
import { campaignMap } from 'config/constants/campaigns'
import { Achievement } from 'state/types'
import { multicallv2 } from 'utils/multicall'
import { getPointCenterIgoAddress } from 'utils/addressHelpers'
import pointCenterIgoABI from 'config/abi/pointCenterIgo.json'

interface IgoMapResponse {
  thresholdToClaim: string
  campaignId: string
  numberPoints: BigNumber
}

export const getAchievementTitle = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
    case 'igo':
      return {
        key: 'IFO Shopper: %title%',
        data: {
          title: campaign.title as string,
        },
      }
    default:
      return campaign.title
  }
}

export const getAchievementDescription = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
    case 'igo':
      return {
        key: 'Committed more than $5 worth of LP in the %title% IFO',
        data: {
          title: campaign.title as string,
        },
      }
    default:
      return campaign.description
  }
}

/**
 * Checks if a wallet is eligible to claim points from valid IFO's
 */
export const getClaimableIgoData = async (account: string): Promise<Achievement[]> => {
  const igoCampaigns = igosList.filter((igoItem) => igoItem.campaignId !== undefined)

  // Returns the claim status of every IFO with a campaign ID
  const claimStatusCalls = igoCampaigns.map(({ address }) => {
    return {
      address: getPointCenterIgoAddress(),
      name: 'checkClaimStatus',
      params: [account, address],
    }
  })

  const claimStatuses = (await multicallv2(pointCenterIgoABI, claimStatusCalls, { requireSuccess: false })) as
    | [boolean][]
    | null

  // Get IFO data for all IFO's that are eligible to claim
  const claimableIgoData = (await multicallv2(
    pointCenterIgoABI,
    claimStatuses.reduce((accum, claimStatusArr, index) => {
      if (claimStatusArr === null) {
        return accum
      }

      const [claimStatus] = claimStatusArr

      if (claimStatus === true) {
        return [...accum, { address: getPointCenterIgoAddress(), name: 'igos', params: [igoCampaigns[index].address] }]
      }

      return accum
    }, []),
  )) as IgoMapResponse[]

  // Transform response to an Achievement
  return claimableIgoData.reduce((accum, claimableIgoDataItem) => {
    const claimableCampaignId = claimableIgoDataItem.campaignId.toString()
    if (!campaignMap.has(claimableCampaignId)) {
      return accum
    }

    const campaignMeta = campaignMap.get(claimableCampaignId)
    const { address } = igoCampaigns.find((igoCampaign) => igoCampaign.campaignId === claimableCampaignId)

    return [
      ...accum,
      {
        address,
        id: claimableCampaignId,
        type: 'igo',
        title: getAchievementTitle(campaignMeta),
        description: getAchievementDescription(campaignMeta),
        badge: campaignMeta.badge,
        points: claimableIgoDataItem.numberPoints.toNumber(),
      },
    ]
  }, [])
}
