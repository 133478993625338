import snapshot from '@snapshot-labs/snapshot.js'
import BigNumber from 'bignumber.js'
import { SNAPSHOT_HUB_API } from 'config/constants/endpoints'
import tokens from 'config/constants/tokens'
import { Proposal, ProposalState, ProposalType, Vote } from 'state/types'
import { snapshotStrategies, createPoolStrategy } from 'config/constants/snapshot'
import { ADMINS, PANCAKE_SPACE, SNAPSHOT_VERSION } from './config'

export const isCoreProposal = (proposal: Proposal) => {
  return ADMINS.includes(proposal.author.toLowerCase())
}

export const filterProposalsByType = (proposals: Proposal[], proposalType: ProposalType) => {
  switch (proposalType) {
    case ProposalType.COMMUNITY:
      return proposals.filter((proposal) => !isCoreProposal(proposal))
    case ProposalType.CORE:
      return proposals.filter((proposal) => isCoreProposal(proposal))
    case ProposalType.ALL:
    default:
      return proposals
  }
}

export const filterProposalsByState = (proposals: Proposal[], state: ProposalState) => {
  return proposals.filter((proposal) => proposal.state === state)
}

export interface Message {
  address: string
  msg: string
  sig: string
}

/**
 * Generates metadata required by snapshot to validate payload
 */
export const generateMetaData = () => {
  return {
    plugins: {},
    network: 56,
    strategies: [{ name: 'xp', params: { symbol: 'XP', address: tokens.xp.address, decimals: 18 } }],
  }
}

/**
 * Returns data that is required on all snapshot payloads
 */
export const generatePayloadData = () => {
  return {
    version: SNAPSHOT_VERSION,
    timestamp: (Date.now() / 1e3).toFixed(),
    space: PANCAKE_SPACE,
  }
}

/**
 * General function to send commands to the snapshot api
 */
export const sendSnapshotData = async (message: Message) => {
  const response = await fetch(SNAPSHOT_HUB_API, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  })

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.error_description)
  }

  const data = await response.json()
  return data
}

export const getVotingPower = async (account: string, poolAddresses: string[], blockNumber?: number) => {
  const votingPowerList = await getVotingPowerList([account], poolAddresses, blockNumber)
  return votingPowerList[0]
}

export const calculateVoteResults = (votes: Vote[]): { [key: string]: Vote[] } => {
  return votes.reduce((accum, vote) => {
    const choiceText = vote.proposal.choices[vote.choice - 1]

    return {
      ...accum,
      [choiceText]: accum[choiceText] ? [...accum[choiceText], vote] : [vote],
    }
  }, {})
}

export const getTotalFromVotes = (votes: Vote[]) => {
  return votes.reduce((accum, vote) => {
    let power = parseFloat(vote.metadata?.votingPower)

    if (!power) {
      power = 0
    }

    return accum + power
  }, 0)
}

type ScoresResponseByAddress = {
  [address: string]: number
}

const TEN_POW_18 = new BigNumber(10).pow(18)

type GetScoresResponse = ScoresResponseByAddress[]
type ScoresListIndex = {
  xpBalances: number
  xpVaultShares: number
  xpVaultPricePerFullShares: number
  igoPoolShares: number
  igoPoolPricePerFullShares: number
  userStakeInXpPools: number
  xpBnbLpTotalSupplies: number
  xpBnbLpReserve0s: number
  xpBnbLpXpBnbBalances: number
  poolStart: number
}

function calculateVotingPower(scoresList: GetScoresResponse, voters: string[], scoresListIndex: ScoresListIndex) {
  let [
    xpBalances,
    xpVaultShares,
    xpVaultPricePerFullShares,
    igoPoolShares,
    igoPoolPricePerFullShares,
    userStakeInXpPools,
    xpBnbLpTotalSupplies,
    xpBnbLpReserve0s,
    xpBnbLpXpBnbBalances,
  ] = new Array(9)
  const defaultScore = {}
  for (let i = 0; i < voters.length; i++) {
    defaultScore[voters[i]] = 0
  }
  xpBalances = scoresListIndex.xpBalances > -1 ? scoresList[scoresListIndex.xpBalances] : defaultScore
  xpVaultShares = scoresListIndex.xpVaultShares > -1 ? scoresList[scoresListIndex.xpVaultShares] : defaultScore
  xpVaultPricePerFullShares =
    scoresListIndex.xpVaultPricePerFullShares > -1
      ? scoresList[scoresListIndex.xpVaultPricePerFullShares]
      : defaultScore
  igoPoolShares = scoresListIndex.igoPoolShares > -1 ? scoresList[scoresListIndex.igoPoolShares] : defaultScore
  igoPoolPricePerFullShares =
    scoresListIndex.igoPoolPricePerFullShares > -1
      ? scoresList[scoresListIndex.igoPoolPricePerFullShares]
      : defaultScore
  userStakeInXpPools =
    scoresListIndex.userStakeInXpPools > -1 ? scoresList[scoresListIndex.userStakeInXpPools] : defaultScore
  xpBnbLpTotalSupplies =
    scoresListIndex.xpBnbLpTotalSupplies > -1 ? scoresList[scoresListIndex.xpBnbLpTotalSupplies] : defaultScore
  xpBnbLpReserve0s =
    scoresListIndex.xpBnbLpReserve0s > -1 ? scoresList[scoresListIndex.xpBnbLpReserve0s] : defaultScore
  xpBnbLpXpBnbBalances =
    scoresListIndex.xpBnbLpXpBnbBalances > -1 ? scoresList[scoresListIndex.xpBnbLpXpBnbBalances] : defaultScore

  const result = voters.map((address) => {
    const xpBalance = new BigNumber(xpBalances[address])
    // calculate xpVaultBalance
    const sharePrice = new BigNumber(xpVaultPricePerFullShares[address]).div(TEN_POW_18)
    const xpVaultBalance = new BigNumber(xpVaultShares[address]).times(sharePrice)

    // calculate igoPoolBalance
    const IFOPoolsharePrice = new BigNumber(igoPoolPricePerFullShares[address]).div(TEN_POW_18)
    const IFOPoolBalance = new BigNumber(igoPoolShares[address]).times(IFOPoolsharePrice)

    const xpPoolBalance = new BigNumber(userStakeInXpPools[address])
    // calculate xpBnbLpBalance
    const totalSupplyLP = new BigNumber(xpBnbLpTotalSupplies[address])
    const xpBnbLpReserve0 = new BigNumber(xpBnbLpReserve0s[address])
    const xpBnbLpXpBnbBalance = new BigNumber(xpBnbLpXpBnbBalances[address])
    const xpBnbLpBalance = xpBnbLpXpBnbBalance.times(xpBnbLpReserve0).div(totalSupplyLP)

    // calculate poolsBalance
    const poolStartIndex = scoresListIndex.poolStart
    let poolsBalance = new BigNumber(0)
    for (let i = poolStartIndex; i < scoresList.length; i++) {
      const currentPoolBalance = new BigNumber(scoresList[i][address])
      poolsBalance = poolsBalance.plus(currentPoolBalance)
    }

    const total = xpBalance
      .plus(xpVaultBalance)
      .plus(xpPoolBalance)
      .plus(IFOPoolBalance)
      .plus(xpBnbLpBalance)
      .plus(poolsBalance)
      .div(TEN_POW_18)
      .toFixed(18)
    return {
      xpBalance: xpBalance.div(TEN_POW_18).toFixed(18),
      xpVaultBalance: xpVaultBalance.div(TEN_POW_18).toFixed(18),
      IFOPoolBalance: IFOPoolBalance.div(TEN_POW_18).toFixed(18),
      xpPoolBalance: xpPoolBalance.div(TEN_POW_18).toFixed(18),
      xpBnbLpBalance: xpBnbLpBalance.div(TEN_POW_18).toFixed(18),
      poolsBalance: poolsBalance.div(TEN_POW_18).toFixed(18),
      total,
      voter: address,
    }
  })
  return result
}

const ContractDeployedNumber = {
  Xp: 693963,
  XpVault: 6975840,
  IFOPool: 13463954,
  MasterGamer: 699498,
  XpLp: 6810706,
}

function verifyDefaultContract(blockNumber: number) {
  return {
    Xp: ContractDeployedNumber.Xp < blockNumber,
    XpVault: ContractDeployedNumber.XpVault < blockNumber,
    IFOPool: ContractDeployedNumber.IFOPool < blockNumber,
    MasterGamer: ContractDeployedNumber.MasterGamer < blockNumber,
    XpLp: ContractDeployedNumber.XpLp < blockNumber,
  }
}

export async function getVotingPowerList(voters: string[], poolAddresses: string[], blockNumber: number) {
  const poolsStrategyList = poolAddresses.map((address) => createPoolStrategy(address))
  const contractsValid = verifyDefaultContract(blockNumber)
  const scoresListIndex = {
    xpBalances: -1,
    xpVaultShares: -1,
    xpVaultPricePerFullShares: -1,
    igoPoolShares: -1,
    igoPoolPricePerFullShares: -1,
    userStakeInXpPools: -1,
    xpBnbLpTotalSupplies: -1,
    xpBnbLpReserve0s: -1,
    xpBnbLpXpBnbBalances: -1,
    poolStart: 0,
  }
  const defaultStrategy = []
  let indexCounter = 0
  if (contractsValid.Xp) {
    defaultStrategy.push(snapshotStrategies[0])
    scoresListIndex.xpBalances = indexCounter++
  }
  if (contractsValid.XpVault) {
    defaultStrategy.push(snapshotStrategies[1])
    scoresListIndex.xpVaultShares = indexCounter++
    defaultStrategy.push(snapshotStrategies[2])
    scoresListIndex.xpVaultPricePerFullShares = indexCounter++
  }
  if (contractsValid.IFOPool) {
    defaultStrategy.push(snapshotStrategies[3])
    scoresListIndex.igoPoolShares = indexCounter++
    defaultStrategy.push(snapshotStrategies[4])
    scoresListIndex.igoPoolPricePerFullShares = indexCounter++
  }
  if (contractsValid.MasterGamer) {
    defaultStrategy.push(snapshotStrategies[5])
    scoresListIndex.userStakeInXpPools = indexCounter++
  }
  if (contractsValid.XpLp) {
    defaultStrategy.push(snapshotStrategies[6])
    scoresListIndex.xpBnbLpTotalSupplies = indexCounter++
    defaultStrategy.push(snapshotStrategies[7])
    scoresListIndex.xpBnbLpReserve0s = indexCounter++
    defaultStrategy.push(snapshotStrategies[8])
    scoresListIndex.xpBnbLpXpBnbBalances = indexCounter++
  }
  scoresListIndex.poolStart = indexCounter

  const strategies = [...defaultStrategy, ...poolsStrategyList]
  const network = '56'
  const strategyResponse = await snapshot.utils.getScores(PANCAKE_SPACE, strategies, network, voters, blockNumber)
  const votingPowerList = calculateVotingPower(strategyResponse, voters, scoresListIndex)
  return votingPowerList
}
