import { serializeTokens} from './tokens'
import { SerializedStudioConfig } from './types'

const serializedTokens = serializeTokens()

const studios: SerializedStudioConfig[] = [
  /**
   * These 3 studios (PID 0, 251, 252) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'XP',
  //   lpAddresses: {
  //     1666700000: '',
  //     1666600000: '0xf8c895EDaB3E9cF513B22769254C89C4f20C96fe',
  //   },
  //   token: serializedTokens.boost,
  //   quoteToken: serializedTokens.wavax,
  // },
  {
    pid: 1,
    name: 'Bethesda',
    games: [
      {
        1666700000: '',
        1666600000: '',
      }
    ]
  },
  {
    pid: 2,
    name: 'Bungie',
    games: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
  ,
  {
    pid: 3,
    name: 'DFK',
    games: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
  // {
  //   pid: 1,
  //   lpSymbol: 'XP-USDC LP',
  //   lpAddresses: {
  //       1666700000: '',
  //       1666600000: '0x74bA5996E7461c63B4Cf63f47A571605D2e13651',
  //     },
  //     token: serializedTokens.xp,
  //     quoteToken: serializedTokens.wavax,
  //   },
  // {
  //   pid: 3,
  //   lpSymbol: 'ETH-AVAX LP',
  //   lpAddresses: {
  //     1666700000: '',
  //     1666600000: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.wavax,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'BTC-AVAX LP',
  //   lpAddresses: {
  //     1666700000: '',
  //     1666600000: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.wavax,
  // }
]

export default studios
