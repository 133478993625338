import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import coreManagerABI from 'config/abi/coreManager.json'
import multicall from 'utils/multicall'
import { getCoreManagerAddress } from 'utils/addressHelpers'

export const fetchPublicCoreManager = async () => {
  const coreManagerAddress = getCoreManagerAddress()

  //keycards
  const callCoreSlots = {
    address: coreManagerAddress,
    name: 'coreSlots',
    params: ['1']
  }


  const calls = [
    callCoreSlots
  ]


  const rawCoreManagerAmounts = await multicall<BigNumber[]>(coreManagerABI, calls)

  const parsedAmounts = rawCoreManagerAmounts.map((coreManagerBalance) => {
    return new BigNumber(coreManagerBalance).toJSON()
  })

  return {
    coreSlots: parsedAmounts[0]
  }
}
