import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  LoginIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  Text
} from '@thenexlabs/uikit'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { FetchStatus } from 'config/constants/types'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenutItem'
import WalletUserMenuItem from './WalletUserMenuItem'
import { NextLinkFromReactRouter } from 'components/NextLink'

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE)

  const username = profile ? (profile.jwt.username ? profile.jwt.username:profile.jwt.name) : null;
  
  if (!account) {
    return (
      <UIKitUserMenu account={account} avatarSrc={avatarSrc}>
        {username &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Text color="secondary">
                {username}
              </Text>
            </div>
            <UserMenuDivider />
            <NextLinkFromReactRouter to="/profile/subscriptions" prefetch={false} >
              <UserMenuItem as="button">
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  {t('Subscriptions')}
                </Flex>
              </UserMenuItem>
            </NextLinkFromReactRouter>
            <UserMenuDivider />
          </>
        }
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <ConnectWalletButton scale="sm" />
        </div>
        {profile ?
          <UserMenuItem as="button" onClick={logout}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              {t('Log Out')}
              <LogoutIcon />
            </Flex>
          </UserMenuItem>
          :
          <NextLinkFromReactRouter to="/login" prefetch={false} >
            <UserMenuItem as="button">
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                {t('Log In')}
                <LoginIcon />
              </Flex>
            </UserMenuItem>
          </NextLinkFromReactRouter>
        }
      </UIKitUserMenu>
    )
  }

  return (
    <UIKitUserMenu account={account} avatarSrc={avatarSrc}>
      {username &&
        <>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <Text color="secondary">
              {username}
            </Text>
          </div>
          <UserMenuDivider />
          <NextLinkFromReactRouter to="/profile/subscriptions" prefetch={false} >
            <UserMenuItem as="button">
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                {t('Subscriptions')}
              </Flex>
            </UserMenuItem>
          </NextLinkFromReactRouter>
          <UserMenuDivider />
        </>
      }
      <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} />
      <UserMenuItem as="button" onClick={onPresentTransactionModal}>
        {t('Transactions')}
      </UserMenuItem>
      <UserMenuDivider />
      {/* <UserMenuItem as="button" onClick={() => router.push(`${nftsBaseUrl}/profile/${account.toLowerCase()}`)}>
        {t('Your NFTs')}
      </UserMenuItem>
      <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} />
      <UserMenuDivider /> */}
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {!profile ?
            t('Disconnect')
            :
            t('Disconnect & Log Out')
          }
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
      {!profile &&
        <NextLinkFromReactRouter to="/login" prefetch={false} >
            <UserMenuItem as="button">
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                {t('Log In')}
                <LoginIcon />
              </Flex>
            </UserMenuItem>
          </NextLinkFromReactRouter>
      }
    </UIKitUserMenu>
  )
}

export default UserMenu
