import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchCoreManagerPublicDataAsync, fetchCoreManagerUserDataAsync } from '.'
import { DeserializedCoreManager, DeserializedCoreManagerUserData, DeserializedCoreManagerState, SerializedCoreManager, SerializedCoreManagerUserData, State } from '../types'

const deserializeCoreManagerData = (data: SerializedCoreManager): DeserializedCoreManager => {
  return {
    coreSlots: data.coreSlots ? new BigNumber(data.coreSlots) : BIG_ZERO,
  }
}

const deserializeCoreManagerUserData = (data: SerializedCoreManagerUserData): DeserializedCoreManagerUserData => {
  return {
    tierEquipped: data.tierEquipped ? data.tierEquipped.toString() : BIG_ZERO,
    dataStickIds: data.dataStickIds,
    currentUses: data.currentUses
  }
}

export const useCoreManager = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchCoreManagerPublicDataAsync(''))
    dispatch(fetchCoreManagerUserDataAsync(account))
  }, [account, dispatch])
}

export const useCoreManagerData = (): DeserializedCoreManager => {
  const coreManagerData = useSelector((state: State) => state.coreManager.data)

  if(!coreManagerData){
    return {
      coreSlots: new BigNumber('0')
    }
  }

  const deserializedCoreManagerData: DeserializedCoreManager = deserializeCoreManagerData(coreManagerData)

  return deserializeCoreManagerData(coreManagerData)
}

export const useCoreManagerUserData = (): DeserializedCoreManagerUserData => {
  const coreManagerUserData = useSelector((state: State) => state.coreManager.userData)

  if(!coreManagerUserData){
    return {
      tierEquipped: new BigNumber('0'),
      dataStickIds: [],
      currentUses: []
    }
  }

  const deserializedCoreManagerUserData: DeserializedCoreManagerUserData = deserializeCoreManagerUserData(coreManagerUserData)

  return deserializeCoreManagerUserData(coreManagerUserData)
}
