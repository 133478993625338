import BigNumber from 'bignumber.js'
import igoPoolAbi from 'config/abi/igoPool.json'
import { getIgoPoolAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'

const fetchIgoPoolUser = async (account: string) => {
  try {
    const calls = ['userInfo', 'getUserCredit'].map((method) => ({
      address: getIgoPoolAddress(),
      name: method,
      params: [account],
    }))
    const [userContractResponse, creditResponse] = await multicallv2(igoPoolAbi, calls)

    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      xpAtLastUserAction: new BigNumber(userContractResponse.xpAtLastUserAction.toString()).toJSON(),
      credit: new BigNumber(creditResponse.avgBalance.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      xpAtLastUserAction: null,
      credit: null,
    }
  }
}

export default fetchIgoPoolUser
