import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchPublicDataAsyncGLITCH, fetchUserDataAsyncGLITCH } from '.'
import { DeserializedGLITCH, DeserializedUserDataGLITCH, DeserializedStateGLITCH, SerializedGLITCH, SerializedUserDataGLITCH, State } from '../types'

const deserializeDataGLITCH = (data: SerializedGLITCH): DeserializedGLITCH => {
  return {
    totalSupply: data.totalSupply ? new BigNumber(data.totalSupply) : BIG_ZERO,
    saleState: data.saleState ? new BigNumber(data.saleState) : BIG_ZERO,
    maxSupply: data.maxSupply ? new BigNumber(data.maxSupply) : BIG_ZERO,
  }
}

const deserializeUserDataGLITCH = (data: SerializedUserDataGLITCH): DeserializedUserDataGLITCH => {
  return {
    numMinted: data.numMinted ? new BigNumber(data.numMinted) : BIG_ZERO,
    balance: data.balance ? new BigNumber(data.balance) : BIG_ZERO
  }
}

export const useGLITCH = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchPublicDataAsyncGLITCH(''))
    dispatch(fetchUserDataAsyncGLITCH(account))
  }, [account, dispatch])
}

export const useDataGLITCH = (): DeserializedGLITCH => {
  const glitchData = useSelector((state: State) => state.glitch.data)

  if(!glitchData){
    return {
      totalSupply: new BigNumber('0'),
      saleState: new BigNumber('0'),
      maxSupply: new BigNumber('0')
    }
  }

  const deserializedDataGLITCH: DeserializedGLITCH = deserializeDataGLITCH(glitchData)

  return deserializeDataGLITCH(glitchData)
}

export const useUserDataGLITCH = (): DeserializedUserDataGLITCH => {
  const glitchUserData = useSelector((state: State) => state.glitch.userData)

  if(!glitchUserData){
    return {
      numMinted: new BigNumber('0'),
      balance: new BigNumber('0')
    }
  }

  const deserializedUserDataGLITCH: DeserializedUserDataGLITCH = deserializeUserDataGLITCH(glitchUserData)

  return deserializeUserDataGLITCH(glitchUserData)
}
