import { VaultKey } from 'state/types'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  [VaultKey.XpVault]: {
    name: 'Auto XP',
    description: 'Automatic restaking',
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.xp.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IgoPool]: {
    name: 'IFO XP',
    description: 'Stake XP to participate in IFOs',
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.xp.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.xp,
    earningToken: serializedTokens.xp,
    contractAddress: {
      1666700000: '',
      1666600000: '0x8a60E295e9E817244f112E65b44966fF7D0951c0', //1XP-BOOST
      43113: '0x9C73700A4b6c9ebEf9A18e5323c2a2658f62C665'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.05',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.xp,
    earningToken: serializedTokens.wavax,
    contractAddress: {
      1666700000: '',
      1666600000: '0x55B45b2bf0895A2394627f303F6118E8A4f1D193',
      43113: '0x22E8F9caD81296F89C1eb0654B267160eba4a70f'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.06481',
    deployedBlockNumber: 14875151,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.usdc,
    earningToken: serializedTokens.wavax,
    contractAddress: {
      1666700000: '0',
      1666600000: '0x80FDF6a2FC8945aE1f2c3d45d32FeAeF99A18CD7',
      43113: '0x21c67809058b3B164F5611ad5b4CA293FD27aa87'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.25',
    sortOrder: 999,
    isFinished: true,
  }
]

export default pools
