import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getXpContract,
  getGlitchContract,
  getXpUnlockWrapperContract,
  getNexItemsContract,
  getNexWorldContract,
  getNexSubscriptionsContract,
  getDataSticksContract,
  getCoreManagerContract,
  getAutoBossBattlesContract,
  // getBunnyFactoryContract,
  // getBunnySpecialContract,
  // getPancakeRabbitContract,
  getProfileContract,
  getIgoV1Contract,
  getIgoV2Contract,
  getMasterchefContract,
  getPointCenterIgoContract,
  getSouschefContract,
  getClaimRefundContract,
  // getTradingCompetitionContract,
  // getTradingCompetitionContractV2,
  // getEasterNftContract,
  getErc721Contract,
  getXpVaultContract,
  getIgoPoolContract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getSouschefV2Contract,
  getLotteryV2Contract,
  getBunnySpecialXpVaultContract,
  getBunnySpecialPredictionContract,
  getFarmAuctionContract,
  getBunnySpecialLotteryContract,
  // getAnniversaryAchievementContract,
  getNftMarketContract,
  getNftSaleContract,
  // getPancakeSquadContract,
  getErc721CollectionContract,
  // getBunnySpecialXmasContract,
} from 'utils/contractHelpers'
import { getMulticallAddress } from 'utils/addressHelpers'
import { VaultKey } from 'state/types'
import {
  XpVault,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc20Bytes32,
  IgoPool,
  Multicall,
  Weth,
} from 'config/abi/types'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@thenexlabs/sdk'
import INexDexPairABI from '../config/abi/INexDexPair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract, getProviderOrSigner } from '../utils'

import { INexDexPair } from '../config/abi/types/INexDexPair'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIgoV1Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIgoV1Contract(address, library.getSigner()), [address, library])
}

export const useIgoV2Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIgoV2Contract(address, library.getSigner()), [address, library])
}

export const useERC20 = (address: string, withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getBep20Contract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, address, library, withSignerIfPossible],
  )
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useXpContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getXpContract(library.getSigner()), [library])
}
export const useContractGLITCH = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getGlitchContract(library.getSigner()), [library])
}
export const useXpUnlockWrapperContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getXpUnlockWrapperContract(library.getSigner()), [library])
}
export const useNexItemsContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNexItemsContract(library.getSigner()), [library])
}
export const useNexWorldContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNexWorldContract(library.getSigner()), [library])
}
export const useNexSubscriptionsContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNexSubscriptionsContract(library.getSigner()), [library])
}
export const useDataSticksContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getDataSticksContract(library.getSigner()), [library])
}
export const useCoreManagerContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCoreManagerContract(library.getSigner()), [library])
}
export const useAutoBossBattlesContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getAutoBossBattlesContract(library.getSigner()), [library])
}

// export const useBunnyFactory = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library])
// }
//
// export const usePancakeRabbits = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getPancakeRabbitContract(library.getSigner()), [library])
// }

export const useProfileContract = (withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getProfileContract(withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [withSignerIfPossible, account, library],
  )
}

export const useLotteryV2Contract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLotteryV2Contract(library.getSigner()), [library])
}

export const useMasterchef = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}

export const useSousChef = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export const useSousChefV2 = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefV2Contract(id, library.getSigner()), [id, library])
}

export const usePointCenterIgoContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPointCenterIgoContract(library.getSigner()), [library])
}

// export const useBunnySpecialContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnySpecialContract(library.getSigner()), [library])
// }

export const useClaimRefundContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getClaimRefundContract(library.getSigner()), [library])
}

// export const useTradingCompetitionContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getTradingCompetitionContract(library.getSigner()), [library])
// }

// export const useTradingCompetitionContractV2 = (withSignerIfPossible = true) => {
//   const { library, account } = useActiveWeb3React()
//   return useMemo(
//     () => getTradingCompetitionContractV2(withSignerIfPossible ? getProviderOrSigner(library, account) : null),
//     [library, withSignerIfPossible, account],
//   )
// }

// export const useEasterNftContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getEasterNftContract(library.getSigner()), [library])
// }

export const useVaultPoolContract = (vaultKey: VaultKey): XpVault | IgoPool => {
  const { library } = useActiveWeb3React()
  return useMemo(() => {
    return vaultKey === VaultKey.XpVault
      ? getXpVaultContract(library.getSigner())
      : getIgoPoolContract(library.getSigner())
  }, [library, vaultKey])
}

export const useXpVaultContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getXpVaultContract(library.getSigner()), [library])
}

export const useIgoPoolContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIgoPoolContract(library.getSigner()), [library])
}

export const usePredictionsContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPredictionsContract(library.getSigner()), [library])
}

// export const useChainlinkOracleContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getChainlinkOracleContract(library.getSigner()), [library])
// }

// export const useSpecialBunnyXpVaultContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnySpecialXpVaultContract(library.getSigner()), [library])
// }
//
// export const useSpecialBunnyPredictionContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnySpecialPredictionContract(library.getSigner()), [library])
// }
//
// export const useBunnySpecialLotteryContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnySpecialLotteryContract(library.getSigner()), [library])
// }
//
// export const useBunnySpecialXmasContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getBunnySpecialXmasContract(library.getSigner()), [library])
// }
//
// export const useAnniversaryAchievementContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getAnniversaryAchievementContract(library.getSigner()), [library])
// }
//
// export const useNftSaleContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getNftSaleContract(library.getSigner()), [library])
// }
//
// export const usePancakeSquadContract = () => {
//   const { library } = useActiveWeb3React()
//   return useMemo(() => getPancakeSquadContract(library.getSigner()), [library])
// }

export const useFarmAuctionContract = (withSignerIfPossible = true) => {
  const { account, library } = useActiveWeb3React()
  return useMemo(
    () => getFarmAuctionContract(withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [library, account, withSignerIfPossible],
  )
}

export const useNftMarketContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftMarketContract(library.getSigner()), [library])
}

export const useErc721CollectionContract = (collectionAddress: string, withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React()
  return useMemo(() => {
    return getErc721CollectionContract(
      withSignerIfPossible ? getProviderOrSigner(library, account) : null,
      collectionAddress,
    )
  }, [account, library, collectionAddress, withSignerIfPossible])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, withSignerIfPossible ? getProviderOrSigner(library, account) : null)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.AVALANCHE_MAINNET:
      case ChainId.AVALANCHE_TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract<EnsRegistrar>(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract<Erc20Bytes32>(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): INexDexPair | null {
  return useContract(pairAddress, INexDexPairABI, withSignerIfPossible)
}

export function useMulticallContract() {
  return useContract<Multicall>(getMulticallAddress(), multiCallAbi, false)
}
