import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getXpAddress,
  getGlitchAddress,
  getXpUnlockWrapperAddress,
  getNexItemsAddress,
  getNexWorldAddress,
  getNexSubscriptionsAddress,
  getDataSticksAddress,
  getCoreManagerAddress,
  getAutoBossBattlesAddress,
  getPancakeProfileAddress,
  // getPancakeRabbitsAddress,
  // getBunnyFactoryAddress,
  // getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterGamerAddress,
  getPointCenterIgoAddress,
  getClaimRefundAddress,
  // getTradingCompetitionAddress,
  // getEasterNftAddress,
  getXpVaultAddress,
  getIgoPoolAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialXpVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  // getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getPancakeSquadAddress,
  // getTradingCompetitionAddressV2,
  // getBunnySpecialXmasAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import xpAbi from 'config/abi/xp.json'
import glitchAbi from 'config/abi/glitch.json'
import xpUnlockWrapperAbi from 'config/abi/xpUnlockWrapper.json'
import nexItemsAbi from 'config/abi/nexItems.json'
import nexWorldAbi from 'config/abi/nexWorld.json'
import nexSubscriptionsAbi from 'config/abi/nexSubscriptions.json'
import dataSticksAbi from 'config/abi/dataSticks.json'
import coreManagerAbi from 'config/abi/coreManager.json'
import autoBossBattlesAbi from 'config/abi/autoBossBattles.json'

import igoV1Abi from 'config/abi/igoV1.json'
import igoV2Abi from 'config/abi/igoV2.json'
import pointCenterIgo from 'config/abi/pointCenterIgo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import tradingCompetitionV2Abi from 'config/abi/tradingCompetitionV2.json'
import easterNftAbi from 'config/abi/easterNft.json'
import xpVaultAbi from 'config/abi/xpVault.json'
import igoPoolAbi from 'config/abi/igoPool.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialXpVaultAbi from 'config/abi/bunnySpecialXpVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import bunnySpecialXmasAbi from 'config/abi/bunnySpecialXmas.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import pancakeSquadAbi from 'config/abi/pancakeSquad.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'

// Types
import {
  ChainlinkOracle,
  FarmAuction,
  Predictions,
  AnniversaryAchievement,
  IgoV1,
  IgoV2,
  IgoPool,
  Erc20,
  Erc721,
  Xp,
  Glitch,
  XpUnlockWrapper,
  NexItems,
  NexWorld,
  NexSubscriptions,
  DataSticks,
  CoreManager,
  AutoBossBattles,
  BunnyFactory,
  PancakeRabbits,
  PancakeProfile,
  LotteryV2,
  Masterchef,
  SousChef,
  SousChefV2,
  BunnySpecial,
  LpToken,
  ClaimRefund,
  TradingCompetition,
  TradingCompetitionV2,
  EasterNft,
  XpVault,
  Multicall,
  BunnySpecialXpVault,
  BunnySpecialPrediction,
  BunnySpecialLottery,
  NftMarket,
  NftSale,
  PancakeSquad,
  Erc721collection,
  PointCenterIgo,
} from 'config/abi/types'

const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(bep20Abi, address, signer) as Erc20
}
export const getErc721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(erc721Abi, address, signer) as Erc721
}
export const getLpContract = (address: string, signer?: Signer | Provider) => {
  return getContract(lpTokenAbi, address, signer) as LpToken
}
export const getIgoV1Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(igoV1Abi, address, signer) as IgoV1
}
export const getIgoV2Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(igoV2Abi, address, signer) as IgoV2
}
export const getSouschefContract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer) as SousChef
}
export const getSouschefV2Contract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer) as SousChefV2
}
export const getPointCenterIgoContract = (signer?: Signer | Provider) => {
  return getContract(pointCenterIgo, getPointCenterIgoAddress(), signer) as PointCenterIgo
}
export const getXpContract = (signer?: Signer | Provider) => {
  return getContract(xpAbi, getXpAddress(), signer) as Xp
}
export const getGlitchContract = (signer?: Signer | Provider) => {
  return getContract(glitchAbi, getGlitchAddress(), signer) as Glitch
}
export const getXpUnlockWrapperContract = (signer?: Signer | Provider) => {
  return getContract(xpUnlockWrapperAbi, getXpUnlockWrapperAddress(), signer) as XpUnlockWrapper
}
export const getNexItemsContract = (signer?: Signer | Provider) => {
  return getContract(nexItemsAbi, getNexItemsAddress(), signer) as NexItems
}
export const getNexWorldContract = (signer?: Signer | Provider) => {
  return getContract(nexWorldAbi, getNexWorldAddress(), signer) as NexWorld
}
export const getNexSubscriptionsContract = (signer?: Signer | Provider) => {
  return getContract(nexSubscriptionsAbi, getNexSubscriptionsAddress(), signer) as NexSubscriptions
}
export const getDataSticksContract = (signer?: Signer | Provider) => {
  return getContract(dataSticksAbi, getDataSticksAddress(), signer) as DataSticks
}
export const getCoreManagerContract = (signer?: Signer | Provider) => {
  return getContract(coreManagerAbi, getCoreManagerAddress(), signer) as CoreManager
}
export const getAutoBossBattlesContract = (signer?: Signer | Provider) => {
  return getContract(autoBossBattlesAbi, getAutoBossBattlesAddress(), signer) as AutoBossBattles
}
export const getProfileContract = (signer?: Signer | Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer) as PancakeProfile
}
// export const getPancakeRabbitContract = (signer?: Signer | Provider) => {
//   return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer) as PancakeRabbits
// }
// export const getBunnyFactoryContract = (signer?: Signer | Provider) => {
//   return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer) as BunnyFactory
// }
// export const getBunnySpecialContract = (signer?: Signer | Provider) => {
//   return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer) as BunnySpecial
// }
export const getLotteryV2Contract = (signer?: Signer | Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer) as LotteryV2
}
export const getMasterchefContract = (signer?: Signer | Provider) => {
  return getContract(masterChef, getMasterGamerAddress(), signer) as Masterchef
}
export const getClaimRefundContract = (signer?: Signer | Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer) as ClaimRefund
}
// export const getTradingCompetitionContract = (signer?: Signer | Provider) => {
//   return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer) as TradingCompetition
// }

// export const getTradingCompetitionContractV2 = (signer?: Signer | Provider) => {
//   return getContract(tradingCompetitionV2Abi, getTradingCompetitionAddressV2(), signer) as TradingCompetitionV2
// }
// export const getEasterNftContract = (signer?: Signer | Provider) => {
//   return getContract(easterNftAbi, getEasterNftAddress(), signer) as EasterNft
// }
export const getXpVaultContract = (signer?: Signer | Provider) => {
  return getContract(xpVaultAbi, getXpVaultAddress(), signer) as XpVault
}
export const getIgoPoolContract = (signer?: Signer | Provider) => {
  return getContract(igoPoolAbi, getIgoPoolAddress(), signer) as IgoPool
}

export const getPredictionsContract = (signer?: Signer | Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer) as unknown as Predictions
}

export const getChainlinkOracleContract = (signer?: Signer | Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainlinkOracle
}
export const getMulticallContract = () => {
  return getContract(MultiCallAbi, getMulticallAddress(), simpleRpcProvider) as Multicall
}
export const getBunnySpecialXpVaultContract = (signer?: Signer | Provider) => {
  return getContract(bunnySpecialXpVaultAbi, getBunnySpecialXpVaultAddress(), signer) as BunnySpecialXpVault
}
export const getBunnySpecialPredictionContract = (signer?: Signer | Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer) as BunnySpecialPrediction
}
export const getBunnySpecialLotteryContract = (signer?: Signer | Provider) => {
  return getContract(bunnySpecialLotteryAbi, getBunnySpecialLotteryAddress(), signer) as BunnySpecialLottery
}
// export const getBunnySpecialXmasContract = (signer?: Signer | Provider) => {
//   return getContract(bunnySpecialXmasAbi, getBunnySpecialXmasAddress(), signer)
// }
export const getFarmAuctionContract = (signer?: Signer | Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as unknown as FarmAuction
}
// export const getAnniversaryAchievementContract = (signer?: Signer | Provider) => {
//   return getContract(anniversaryAchievementAbi, getAnniversaryAchievement(), signer) as AnniversaryAchievement
// }
export const getNftMarketContract = (signer?: Signer | Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer) as NftMarket
}
export const getNftSaleContract = (signer?: Signer | Provider) => {
  return getContract(nftSaleAbi, getNftSaleAddress(), signer) as NftSale
}
// export const getPancakeSquadContract = (signer?: Signer | Provider) => {
//   return getContract(pancakeSquadAbi, getPancakeSquadAddress(), signer) as PancakeSquad
// }
export const getErc721CollectionContract = (signer?: Signer | Provider, address?: string) => {
  return getContract(erc721CollectionAbi, address, signer) as Erc721collection
}
