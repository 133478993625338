import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import glitchABI from 'config/abi/glitch.json'
import multicall from 'utils/multicall'
import { getAddress, getGlitchAddress } from 'utils/addressHelpers'

export const fetchPublicGLITCH = async () => {
  const glitchAddress = getGlitchAddress()

  //glitch
  const callTotalSupply = {
    address: glitchAddress,
    name: 'totalSupply'
  }

  const callSaleState = {
    address: glitchAddress,
    name: 'saleState'
  }

  const callMaxSupply = {
    address: glitchAddress,
    name: 'maxSupply'
  }


  const calls = [
    callTotalSupply,
    callSaleState,
    callMaxSupply
  ]


  const rawGLITCHAmounts = await multicall<BigNumber[]>(glitchABI, calls)

  const parsedAmounts = rawGLITCHAmounts.map((glitchBalance) => {
    return new BigNumber(glitchBalance).toJSON()
  })

  return {
    totalSupply: parsedAmounts[0],
    saleState: parsedAmounts[1],
    maxSupply: parsedAmounts[2]
  }
}
