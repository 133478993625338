import React from 'react'
import { ModalProvider, light, dark } from '@thenexlabs/uikit'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'
import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { ToastsProvider } from 'contexts/ToastsContext'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import { SessionProvider as NextAuthProvider } from 'next-auth/react'

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const Providers: React.FC<{ store: Store; children?: React.ReactNode; }> = ({ children, store }) => {
  return (
    <NextAuthProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <ToastsProvider>
            <ThemeProviderWrapper>
              <LanguageProvider>
                <SWRConfig
                  value={{
                    use: [fetchStatusMiddleware],
                  }}
                >
                  <ModalProvider>{children}</ModalProvider>
                </SWRConfig>
              </LanguageProvider>
            </ThemeProviderWrapper>
          </ToastsProvider>
        </Provider>
      </Web3ReactProvider>
    </NextAuthProvider>
  )
}

export default Providers
