import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicGLITCH } from './fetchGLITCH'
import { fetchUserGLITCH } from './fetchUserGLITCH'
import {
  SerializedStateGLITCH,
  SerializedGLITCH,
  SerializedUserDataGLITCH
} from '../types'


const noDataConfigGLITCH = { // public
  totalSupply: '0',
  saleState: '0',
  maxSupply: '0'
}

const noAccountConfigGLITCH = { // user
  numMinted: '0',
  balance: '0'
}

const initialState: SerializedStateGLITCH = {
  userData: noAccountConfigGLITCH,
  data: noDataConfigGLITCH,
  loadArchivedData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchPublicDataAsyncGLITCH = createAsyncThunk<
  SerializedGLITCH,
  string,
  {
    state: AppState
  }
>(
  'glitch/fetchPublicDataAsyncGLITCH',
  async ( x ) => {

    const payload = await fetchPublicGLITCH()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { glitch } = getState()
      if (glitch.loadingKeys[stringify({ type: fetchPublicDataAsyncGLITCH.typePrefix, arg })]) {
        console.debug('glitch action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)


export const fetchUserDataAsyncGLITCH = createAsyncThunk<
  SerializedUserDataGLITCH,
  string,
  {
    state: AppState
  }
>(
  'glitch/fetchUserDataAsyncGLITCH',
  async ( account ) => {

    const payload = await fetchUserGLITCH(account)

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { glitch } = getState()
      if (glitch.loadingKeys[stringify({ type: fetchUserDataAsyncGLITCH.typePrefix, arg })]) {
        console.debug('glitch user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const glitchSlice = createSlice({
  name: 'GLITCH',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update glitch with live data
    builder.addCase(fetchPublicDataAsyncGLITCH.fulfilled, (state, action) => {
      state.data.totalSupply = action.payload.totalSupply
      state.data.saleState = action.payload.saleState
      state.data.maxSupply = action.payload.maxSupply
    })

    builder.addCase(fetchUserDataAsyncGLITCH.fulfilled, (state, action) => {
      state.userData.numMinted = action.payload.numMinted
      state.userData.balance = action.payload.balance
    })
  },
})

export default glitchSlice.reducer
