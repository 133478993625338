import { ChainId, Currency, currencyEquals, JSBI, Price } from '@thenexlabs/sdk'
import tokens, { mainnetTokens, testnetTokens } from 'config/constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { multiplyPriceByAmount } from 'utils/prices'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { PairState, usePairs } from './usePairs'

const USDC = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID) === ChainId.AVALANCHE_MAINNET as any ? mainnetTokens.usdc: testnetTokens.usdc
const { wavax: WAVAX } = tokens

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useBUSDPrice(currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency(currency, chainId)
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [chainId && wrapped && currencyEquals(WAVAX, wrapped) ? undefined : currency, chainId ? WAVAX : undefined],
      [wrapped?.equals(USDC) ? undefined : wrapped, chainId === undefined ? undefined : USDC],
      [chainId ? WAVAX : undefined, chainId === undefined ? undefined : USDC],
    ],
    [chainId, currency, wrapped],
  )
  const [[ethPairState, ethPair], [busdPairState, busdPair], [busdEthPairState, busdEthPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(WAVAX)) {
      if (busdPair) {
        const price = busdPair.priceOf(WAVAX)
        return new Price(currency, USDC, price.denominator, price.numerator)
      }
      return undefined
    }
    // handle usdc
    if (wrapped.equals(USDC)) {
      return new Price(USDC, USDC, '1', '1')
    }

    const ethPairETHAmount = ethPair?.reserveOf(WAVAX)

    const ethPairETHBUSDValue: JSBI =
      ethPairETHAmount && busdEthPair ? busdEthPair.priceOf(WAVAX).quote(ethPairETHAmount).raw : JSBI.BigInt(0)
    // all other tokens
    // first try the usdc pair
    if (
      busdPairState === PairState.EXISTS &&
      busdPair &&
      busdPair.reserveOf(USDC).greaterThan(ethPairETHBUSDValue)
    ) {
      const price = busdPair.priceOf(wrapped)
      return new Price(currency, USDC, price.denominator, price.numerator)
    }
    if (ethPairState === PairState.EXISTS && ethPair && busdEthPairState === PairState.EXISTS && busdEthPair) {
      if (busdEthPair.reserveOf(USDC).greaterThan('0') && ethPair.reserveOf(WAVAX).greaterThan('0')) {
        const ethBusdPrice = busdEthPair.priceOf(USDC)
        const currencyEthPrice = ethPair.priceOf(WAVAX)
        const busdPrice = ethBusdPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, USDC, busdPrice.denominator, busdPrice.numerator)
      }
    }

    return undefined
  }, [chainId, currency, ethPair, ethPairState, busdEthPair, busdEthPairState, busdPair, busdPairState, wrapped])
}

export const useXpBusdPrice = (): Price | undefined => {
  const xpBusdPrice = useBUSDPrice(tokens.xp)
  return xpBusdPrice
}

export const useBUSDCurrencyAmount = (currency: Currency, amount: number): number | undefined => {
  const { chainId } = useActiveWeb3React()
  const busdPrice = useBUSDPrice(currency)
  const wrapped = wrappedCurrency(currency, chainId)
  if (busdPrice) {
    return multiplyPriceByAmount(busdPrice, amount, wrapped.decimals)
  }
  return undefined
}

export const useBUSDXpAmount = (amount: number): number | undefined => {
  const xpBusdPrice = useXpBusdPrice()
  if (xpBusdPrice) {
    return multiplyPriceByAmount(xpBusdPrice, amount)
  }
  return undefined
}

export const useBNBBusdPrice = (): Price | undefined => {
  const bnbBusdPrice = useBUSDPrice(tokens.wavax)
  return bnbBusdPrice
}
