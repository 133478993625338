import { ChainId, JSBI, Percent, Token } from '@thenexlabs/sdk'
import { mainnetTokens, testnetTokens } from './tokens'

export const ROUTER_ADDRESS = {
  // 43113: '0x5B1623597B7DA758355d718326093CCdF1CE9154',
  43114: '0x568d3310aFB01959A085dB7a1b28CA58180bcece',
  43113: '0x654506ACd9bf60800C5813AFC9C9f013981c1345',
  // 43114: '0x568d3310aFB01959A085dB7a1b28CA58180bcece'
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.AVALANCHE_MAINNET]: [
    mainnetTokens.wavax,
    mainnetTokens.xp,
    mainnetTokens.usdc,
    // mainnetTokens.usdt,
    // mainnetTokens.btcb,
    // mainnetTokens.ust,
    // mainnetTokens.eth,
    // mainnetTokens.usdc,
  ],
  [ChainId.AVALANCHE_TESTNET]: [testnetTokens.wavax, testnetTokens.xp, testnetTokens.usdc],
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.AVALANCHE_MAINNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.AVALANCHE_MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.AVALANCHE_MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.AVALANCHE_MAINNET]: [mainnetTokens.usdc, mainnetTokens.xp, /*mainnetTokens.btcb*/],
  [ChainId.AVALANCHE_TESTNET]: [testnetTokens.wavax, testnetTokens.xp, testnetTokens.usdc],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.AVALANCHE_MAINNET]: [mainnetTokens.wavax, mainnetTokens.usdc, /*mainnetTokens.dai, mainnetTokens.usdt*/],
  [ChainId.AVALANCHE_TESTNET]: [testnetTokens.wavax, testnetTokens.xp, testnetTokens.usdc],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.AVALANCHE_MAINNET]: [
    [mainnetTokens.xp, mainnetTokens.wavax],
    [mainnetTokens.usdc, mainnetTokens.wavax],
    [mainnetTokens.xp, mainnetTokens.usdc],/*
    [mainnetTokens.usdc, mainnetTokens.usdt],
    [mainnetTokens.dai, mainnetTokens.usdt],*/
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much AVAX so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 AVAX
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
  // new additions NEX
  '0x8F0B5bb53cB3897CFe28330d4Ac3b18674915EA4'
]

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as igosConfig } from './igo'
export { default as studiosConfig } from './studios'
export { default as gamesConfig } from './games'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000
