import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import xpABI from 'config/abi/xp.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterGamerAddress, getXpAddress } from 'utils/addressHelpers'


export const fetchUserLockedXp = async (account: string) => {
  const xpAddress = getXpAddress()

  const callLocked = {
    address: xpAddress,
    name: 'lockOf',
    params: [account]
  }

  const callUnlockable = {
    address: xpAddress,
    name: 'unlockableOf',
    params: [account]
  }

  const calls = [
    callLocked,
    callUnlockable
  ]
  const rawLockedXpAmounts = await multicall<BigNumber[]>(xpABI, calls)

  const parsedAmounts = rawLockedXpAmounts.map((xpBalance) => {
    return new BigNumber(xpBalance).toJSON()
  })
  // console.log(parsedAmounts)
  return {
    lockedXp: parsedAmounts[0],
    unlockableXp: parsedAmounts[1]
  }
}
