import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'XP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xf8c895EDaB3E9cF513B22769254C89C4f20C96fe',
      43113: '0xe52388D8660F27fcb73aE2217739f58535DB46B2',
      43114: '0xD24483c0C0f566228F7B5B33Ae737946e3eE1865'
    },
    token: serializedTokens.boost,
    quoteToken: serializedTokens.wavax,
  },
  {
    pid: 1,
    lpSymbol: 'XP-AVAX LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x55B45b2bf0895A2394627f303F6118E8A4f1D193',
      43113: '0x8869C1966863e3D7De3f8f2E027e14A5b809a010',
      43114: '0x8214bD1029889cD7e7fdfDfF89745D503BF1C9A6'
    },
    token: serializedTokens.wavax,
    quoteToken: serializedTokens.xp,
  },
  {
    pid: 2,
    lpSymbol: 'XP-USDC LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '',
      43113: '0x66a8cd2F924E2C343440a0187B8829Fbcec01ac9',
      43114: '0xc7920cA41aDb62a6D7B5496A59b1987E99813CaF'
    },
    token: serializedTokens.xp,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 3,
    lpSymbol: 'USDC-AVAX LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x80FDF6a2FC8945aE1f2c3d45d32FeAeF99A18CD7',
      43113: '0x6438dca1268333ff84310762d51b7154bECE882E',
      43114: '0xa6d14FA4040951D97b34251b819bBB9274987a82'
    },
    token: serializedTokens.wavax,
    quoteToken: serializedTokens.usdc,
  },
  // {
  //   pid: 1,
  //   lpSymbol: 'XP-USDC LP',
  //   lpAddresses: {
  //       1666700000: '',
  //       1666600000: '0x74bA5996E7461c63B4Cf63f47A571605D2e13651',
  //     },
  //     token: serializedTokens.xp,
  //     quoteToken: serializedTokens.wavax,
  //   },
  // {
  //   pid: 3,
  //   lpSymbol: 'ETH-AVAX LP',
  //   lpAddresses: {
  //     1666700000: '',
  //     1666600000: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.wavax,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'BTC-AVAX LP',
  //   lpAddresses: {
  //     1666700000: '',
  //     1666600000: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.wavax,
  // }
]

export default farms
