import { FooterLinkType } from '@thenexlabs/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      // {
      //   label: t('Contact'),
      //   href: 'https://docs.thenex.world/contact-us',
      // },
      // {
      //   label: t('Brand'),
      //   href: 'https://docs.thenex.world/brand',
      // },
      {
        label: t('Nix - AI Cyber Security'),
        href: '/nix/about',
      },
      {
        label: t('Cyber Security Automation'),
        href: '/web4/network-dashboard/about',
      },
      // {
      //   label: t('Cyber Security Services'),
      //   href: '/security/services',
      // },
      {
        label: t('Blog'),
        href: 'https://blog.thenex.world',
      },
      {
        label: t('Medium'),
        href: 'https://medium.com/@thenex.world',
      },
      // {
      //   label: t('Contact Us'),
      //   href: '/contact',
      // },
      // {
      //   label: t('Community'),
      //   href: 'https://docs.thenex.world/contact-us/telegram',
      // },
      // {
      //   label: t('XP token'),
      //   href: 'https://docs.thenex.world/tokenomics/xp',
      // },
      /* {
        label: '—',
      },
      {
        label: t('Online Store'),
        href: 'https://thenex.creator-spring.com/',
        isHighlighted: true,
      }, */
    ],
  },
  // {
  //   label: t('Help'),
  //   items: [
  //     {
  //       label: t('Customer Support'),
  //       href: 'https://docs.thenex.world/contact-us/customer-support',
  //     },
  //     {
  //       label: t('Troubleshooting'),
  //       href: 'https://docs.thenex.world/help/troubleshooting',
  //     },
  //     {
  //       label: t('Guides'),
  //       href: 'https://docs.thenex.world/get-started',
  //     },
  //   ],
  // },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/thenexlabs',
      },
      // {
      //   label: t('Documentation'),
      //   href: 'https://docs.thenex.world',
      // },
      // {
      //   label: t('Bug Bounty'),
      //   href: 'https://docs.thenex.world/code/bug-bounty',
      // },
      // {
      //   label: t('Audits'),
      //   href: 'https://docs.thenex.world/help/faq#is-thenex-safe-has-thenex-been-audited',
      // },
      // {
      //   label: t('Careers'),
      //   href: 'https://docs.thenex.world/hiring/become-a-builder',
      // },
    ],
  },
]
