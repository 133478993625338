import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchDataSticksPublicDataAsync, fetchDataSticksUserDataAsync } from '.'
import { DeserializedDataSticks, DeserializedDataSticksUserData, DeserializedDataSticksState, SerializedDataSticks, SerializedDataSticksUserData, State } from '../types'

const deserializeDataSticksData = (data: SerializedDataSticks): DeserializedDataSticks => {
  const dataSticks = {}
  for (const id in data.dataSticks) {
    dataSticks[id] = {
      totalSupply: data.dataSticks[id].totalSupply ? new BigNumber(data.dataSticks[id].totalSupply) : BIG_ZERO,
      saleState: data.dataSticks[id].saleState ? new BigNumber(data.dataSticks[id].saleState) : BIG_ZERO,
      maxSupply: data.dataSticks[id].maxSupply ? new BigNumber(data.dataSticks[id].maxSupply) : BIG_ZERO,
      uri: data.dataSticks[id].uri ? data.dataSticks[id].uri : '',
    }
  }
  return { dataSticks }
}

const deserializeDataSticksUserData = (data: SerializedDataSticksUserData): DeserializedDataSticksUserData => {
  const dataSticks = {}
  for (const id in data.dataSticks) {
    dataSticks[id] = {
      numMinted: data.dataSticks[id].numMinted ? new BigNumber(data.dataSticks[id].numMinted) : BIG_ZERO,
      balance: data.dataSticks[id].balance ? new BigNumber(data.dataSticks[id].balance) : BIG_ZERO
    }
  }
  return { dataSticks }
}

export const useDataSticks = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchDataSticksPublicDataAsync(''))
    dispatch(fetchDataSticksUserDataAsync(account))
  }, [account, dispatch])
}

export const useDataSticksData = (): DeserializedDataSticks => {
  const dataSticksData = useSelector((state: State) => state.dataSticks.data)

  if(!dataSticksData){
    return {
      dataSticks: {}
    }
  }

  const deserializedDataSticksData: DeserializedDataSticks = deserializeDataSticksData(dataSticksData)

  return deserializedDataSticksData
}

export const useDataSticksUserData = (): DeserializedDataSticksUserData => {
  const dataSticksUserData = useSelector((state: State) => state.dataSticks.userData)

  if(!dataSticksUserData){
    return {
      dataSticks: {}
    }
  }

  const deserializedDataSticksUserData: DeserializedDataSticksUserData = deserializeDataSticksUserData(dataSticksUserData)

  return deserializedDataSticksUserData
}
