import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import nexItemsABI from 'config/abi/nexItems.json'
import multicall from 'utils/multicall'
import { getNexItemsAddress } from 'utils/addressHelpers'


export const fetchUserNexItems = async (account: string) => {
  const nexItemsAddress = getNexItemsAddress()
  const nexItemId = '0'

  const callUserMinted = {
    address: nexItemsAddress,
    name: 'userMintedMap',
    params: [account, nexItemId]
  }

  const callBalance = {
    address: nexItemsAddress,
    name: 'balanceOf',
    params: [account, nexItemId]
  }

  const callUserMintedCore = {
    address: nexItemsAddress,
    name: 'userMintedMap',
    params: [account, '1']
  }

  const callBalanceCore = {
    address: nexItemsAddress,
    name: 'balanceOf',
    params: [account, '1']
  }

  const calls = [
    callUserMinted,
    callBalance,
    callUserMintedCore,
    callBalanceCore
  ]

  const rawNexItemsAmounts = await multicall<BigNumber[]>(nexItemsABI, calls)

  const parsedAmounts = rawNexItemsAmounts.map((nexItemsBalance) => {
    return new BigNumber(nexItemsBalance).toJSON()
  })

  return {
    keycard: {
      numMinted: parsedAmounts[0],
      balance: parsedAmounts[1]
    },
    core: {
      numMinted: parsedAmounts[2],
      balance: parsedAmounts[3]
    }
  }
}
