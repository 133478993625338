import { ChainId, Token } from '@thenexlabs/sdk'
//import { serializeGame } from 'state/user/hooks/helpers'
import { SerializedGameConfig } from './types'

const { AVALANCHE_MAINNET, AVALANCHE_TESTNET } = ChainId

const mainnetGames: SerializedGameConfig[] = [
  /*{
    pid: 0,
    title: 'XP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xf8c895EDaB3E9cF513B22769254C89C4f20C96fe',
    },
    token: serializedGames.boost,
    quoteGame: serializedGames.wavax,
  },*/
  {
    pid: 1,
    title: 'The NEX',
    tokens: [
      {
        1666700000: '',
        1666600000: '',
        43113: ''
      }
    ],
    nftCollections: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
  {
    pid: 2,
    title: 'WenLambo',
    tokens: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
    nftCollections: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
  {
    pid: 3,
    title: 'MercuryONE',
    tokens: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
    nftCollections: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
]

const testnetGames: SerializedGameConfig[] = [
  /*{
    pid: 0,
    title: 'XP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xf8c895EDaB3E9cF513B22769254C89C4f20C96fe',
    },
    token: serializedGames.boost,
    quoteGame: serializedGames.wavax,
  },*/
  {
    pid: 1,
    title: 'WenLambo',
    tokens: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
    nftCollections: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
  {
    pid: 2,
    title: 'MercuryONE',
    tokens: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
    nftCollections: [
      {
        1666700000: '',
        1666600000: '',
      }
    ],
  },
]

const games = () => {
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

  // If testnet - return list comprised of testnetGames wherever they exist, and mainnetGames where they don't
  if (parseInt(chainId, 10) === ChainId.AVALANCHE_TESTNET) {
    return Object.keys(mainnetGames).reduce((accum, key) => {
      return { ...accum, [key]: testnetGames[key] || mainnetGames[key] }
    }, {} as typeof testnetGames & typeof mainnetGames)
  }

  return mainnetGames
}

const unserializedGames = games()

// type SerializedGameList = Record<keyof typeof unserializedGames, SerializedGame>

// export const serializeGames = () => {
//   const serializedGames = Object.keys(unserializedGames).reduce((accum, key) => {
//     return { ...accum, [key]: serializeGame(unserializedGames[key]) }
//   }, {} as SerializedGameList)
//
//   return serializedGames
// }

export default unserializedGames
