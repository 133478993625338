import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import autoBossBattlesABI from 'config/abi/autoBossBattles.json'
import multicall from 'utils/multicall'
import { getAutoBossBattlesAddress } from 'utils/addressHelpers'


export const fetchUserAutoBossBattles = async (account: string) => {
  const autoBossBattlesAddress = getAutoBossBattlesAddress()
  
  const callUsers = {
    address: autoBossBattlesAddress,
    name: 'users',
    params: [account]
  }

  const calls = [
    callUsers
  ]

  const rawAutoBossBattlesAmounts = await multicall<BigNumber[]>(autoBossBattlesABI, calls)

  const parsedAmounts = rawAutoBossBattlesAmounts.map((autoBossBattlesData) => {
    return autoBossBattlesData
  })

  return {
    battle: {
      bossId: parsedAmounts[0][0][0].toString(),
      startTime: parsedAmounts[0][0][1].toString(),
      battleDuration: parsedAmounts[0][0][2].toString(),
      feeAmount: parsedAmounts[0][0][3].toString()
    },
    kills: parsedAmounts[0][1].toString(),
    deaths: parsedAmounts[0][2].toString(),
    // dailyRewardsEarned: parsedAmounts[0][3].toString(),
    // lastTimeEarned: parsedAmounts[0][4].toString(),
    // totalXpEarned: parsedAmounts[0][5].toString()
  }
}
