import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import glitchABI from 'config/abi/glitch.json'
import multicall from 'utils/multicall'
import { getGlitchAddress } from 'utils/addressHelpers'


export const fetchUserGLITCH = async (account: string) => {
  const glitchAddress = getGlitchAddress()

  const callUserMinted = {
    address: glitchAddress,
    name: 'userMinted',
    params: [account]
  }

  const callBalance = {
    address: glitchAddress,
    name: 'balanceOf',
    params: [account]
  }

  const calls = [
    callUserMinted,
    callBalance
  ]

  const rawGLITCHAmounts = await multicall<BigNumber[]>(glitchABI, calls)

  const parsedAmounts = rawGLITCHAmounts.map((glitchBalance) => {
    return new BigNumber(glitchBalance).toJSON()
  })

  return {
    numMinted: parsedAmounts[0],
    balance: parsedAmounts[1]
  }
}
