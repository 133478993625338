import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import xpABI from 'config/abi/xp.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterGamerAddress, getXpAddress } from 'utils/addressHelpers'


export const fetchPublicXp = async () => {
  const xpAddress = getXpAddress()

  const callCap = {
    address: xpAddress,
    name: 'cap'
  }

  const callTotalLocked = {
    address: xpAddress,
    name: 'lockedSupply'
  }

  const callCurrentSupply = {
    address: xpAddress,
    name: 'totalSupply'
  }

  const calls = [
    callCap,
    callTotalLocked,
    callCurrentSupply
  ]

  const rawLockedXpAmounts = await multicall<BigNumber[]>(xpABI, calls)

  const parsedAmounts = rawLockedXpAmounts.map((xpBalance) => {
    return new BigNumber(xpBalance).toJSON()
  })

  return {
    xpCap: parsedAmounts[0],
    totalLockedXp: parsedAmounts[1],
    currentSupply: parsedAmounts[2]
  }
}
