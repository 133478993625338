import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import dataSticksABI from 'config/abi/dataSticks.json'
import multicall from 'utils/multicall'
import { getDataSticksAddress } from 'utils/addressHelpers'

export const fetchPublicDataSticks = async () => {
  const dataSticksAddress = getDataSticksAddress()
  const calls = []

  let id = 1
  let hasMoreDataSticks = true

  while (hasMoreDataSticks) {
    // Check if dataStick ID exists by checking if its totalSupply is greater than 0
    const totalSupplyCall = {
      address: dataSticksAddress,
      name: 'totalSupply',
      params: [id]
    }
    const totalSupplyResponse = await multicall<BigNumber[]>(dataSticksABI, [totalSupplyCall])
    const totalSupply = new BigNumber(totalSupplyResponse[0]).toNumber()

    if (totalSupply > 0) {
      const callTotalSupply = {
        address: dataSticksAddress,
        name: 'totalSupply',
        params: [id]
      }

      const callSaleState = {
        address: dataSticksAddress,
        name: 'saleStateMap',
        params: [id]
      }

      const callMaxSupply = {
        address: dataSticksAddress,
        name: 'maxSupplyMap',
        params: [id]
      }

      const callUri = {
        address: dataSticksAddress,
        name: 'uri',
        params: [id]
      }

      calls.push(callTotalSupply)
      calls.push(callSaleState)
      calls.push(callMaxSupply)
      calls.push(callUri)
    } else {
      hasMoreDataSticks = false
    }

    id++
  }

  const rawDataSticksAmounts = await multicall<BigNumber[]>(dataSticksABI, calls)

  const parsedAmounts = rawDataSticksAmounts.map((dataStickBalance, index) => {
    if((index + 1) % 4 === 0){
        return dataStickBalance.toString();
    }
    return new BigNumber(dataStickBalance).toJSON();
  })

  const dataSticksById = {}

  for (let i = 0; i < id; i++) {
    dataSticksById[i+1] = {
      totalSupply: parsedAmounts[i * 4],
      saleState: parsedAmounts[i * 4 + 1],
      maxSupply: parsedAmounts[i * 4 + 2],
      uri: parsedAmounts[i * 4 + 3]
    }
  }

  return { 
    dataSticks: dataSticksById 
  }
}
