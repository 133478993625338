import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicCoreManager } from './fetchCoreManager'
import { fetchUserCoreManager } from './fetchCoreManagerUser'
import {
  SerializedCoreManagerState,
  SerializedCoreManager,
  SerializedCoreManagerUserData
} from '../types'


const noDataCoreManagerConfig = { // public
  coreSlots: '0'
}

const noAccountCoreManagerConfig = { // user
  tierEquipped: '0',
  dataStickIds: [],
  currentUses: []
}

const initialState: SerializedCoreManagerState = {
  userData: noAccountCoreManagerConfig,
  data: noDataCoreManagerConfig,
  loadArchivedCoreManagerData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchCoreManagerPublicDataAsync = createAsyncThunk<
  SerializedCoreManager,
  string,
  {
    state: AppState
  }
>(
  'coreManager/fetchCoreManagerPublicDataAsync',
  async ( x ) => {

    const payload = await fetchPublicCoreManager()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { coreManager } = getState()
      if (coreManager.loadingKeys[stringify({ type: fetchCoreManagerPublicDataAsync.typePrefix, arg })]) {
        console.debug('coreManager action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)


export const fetchCoreManagerUserDataAsync = createAsyncThunk<
  SerializedCoreManagerUserData,
  string,
  {
    state: AppState
  }
>(
  'coreManager/fetchCoreManagerUserDataAsync',
  async ( account ) => {

    const payload = await fetchUserCoreManager(account)

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { coreManager } = getState()
      if (coreManager.loadingKeys[stringify({ type: fetchCoreManagerUserDataAsync.typePrefix, arg })]) {
        console.debug('coreManager user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const coreManagerSlice = createSlice({
  name: 'CoreManager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update coreManager with live data
    builder.addCase(fetchCoreManagerPublicDataAsync.fulfilled, (state, action) => {
      state.data.coreSlots = action.payload.coreSlots;
    })

    builder.addCase(fetchCoreManagerUserDataAsync.fulfilled, (state, action) => {
      state.userData.tierEquipped = action.payload.tierEquipped;
      state.userData.dataStickIds = action.payload.dataStickIds;
      state.userData.currentUses = action.payload.currentUses;
    })
  },
})

export default coreManagerSlice.reducer
