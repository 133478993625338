import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicDataSticks } from './fetchDataSticks'
import { fetchUserDataSticks } from './fetchDataSticksUser'
import {
  SerializedDataSticksState,
  SerializedDataSticks,
  SerializedDataSticksUserData
} from '../types'


const noDataDataSticksConfig = { // public
  dataSticks: []
}

const noAccountDataSticksConfig = { // user
  dataSticks: []
}

const initialState: SerializedDataSticksState = {
  userData: noAccountDataSticksConfig,
  data: noDataDataSticksConfig,
  loadArchivedDataSticksData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchDataSticksPublicDataAsync = createAsyncThunk<
  SerializedDataSticks,
  string,
  {
    state: AppState
  }
>(
  'dataSticks/fetchDataSticksPublicDataAsync',
  async ( x ) => {

    const payload = await fetchPublicDataSticks()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { dataSticks } = getState()
      if (dataSticks.loadingKeys[stringify({ type: fetchDataSticksPublicDataAsync.typePrefix, arg })]) {
        console.debug('dataSticks action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)


export const fetchDataSticksUserDataAsync = createAsyncThunk<
  SerializedDataSticksUserData,
  string,
  {
    state: AppState
  }
>(
  'dataSticks/fetchDataSticksUserDataAsync',
  async ( account ) => {

    const payload = await fetchUserDataSticks(account)

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { dataSticks } = getState()
      if (dataSticks.loadingKeys[stringify({ type: fetchDataSticksUserDataAsync.typePrefix, arg })]) {
        console.debug('dataSticks user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const dataSticksSlice = createSlice({
  name: 'DataSticks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update dataSticks with live data
    builder.addCase(fetchDataSticksPublicDataAsync.fulfilled, (state, action) => {
      state.data.dataSticks = action.payload.dataSticks
    })

    builder.addCase(fetchDataSticksUserDataAsync.fulfilled, (state, action) => {
      state.userData.dataSticks = action.payload.dataSticks
    })
  },
})

export default dataSticksSlice.reducer
