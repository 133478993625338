import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { LogoIcon } from '@thenexlabs/uikit'

// Define a keyframes animation for the neon glow
const glow = keyframes`
  0% { box-shadow: 0 0 5px white, 0 0 10px lime, 0 0 15px lime, 0 0 20px lime; }
  100% { box-shadow: 0 0 20px white, 0 0 40px lime, 0 0 60px lime, 0 0 160px lime; }
`;

// Define the loading screen component
const Loading = () => {
  const { isDark } = useTheme();

  // Define a styled div for the loading screen
  const LoadingScreen = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${isDark ? 'black' : 'white'};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  // Define a styled div for the neon circle
  const NeonCircle = styled.div`
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background: lime;
    animation: ${glow} .7s infinite alternate;
  `;

  return (
    <LoadingScreen>
      <NeonCircle>
        <LogoIcon width="77px"/>
      </NeonCircle>
    </LoadingScreen>
  );
};

export default Loading;
