import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import nexItemsABI from 'config/abi/nexItems.json'
import multicall from 'utils/multicall'
import { getNexItemsAddress } from 'utils/addressHelpers'

export const fetchPublicNexItems = async () => {
  const nexItemsAddress = getNexItemsAddress()
  const nexItemId = '0'

  //keycards
  const callTotalSupply = {
    address: nexItemsAddress,
    name: 'totalSupply',
    params: [nexItemId]
  }

  const callSaleState = {
    address: nexItemsAddress,
    name: 'saleStateMap',
    params: [nexItemId]
  }

  const callMaxSupply = {
    address: nexItemsAddress,
    name: 'maxSupplyMap',
    params: [nexItemId]
  }

  const callUri = {
    address: nexItemsAddress,
    name: 'uri',
    params: [nexItemId]
  }

  //core
  const callTotalSupplyCore = {
    address: nexItemsAddress,
    name: 'totalSupply',
    params: ['1']
  }

  const callSaleStateCore = {
    address: nexItemsAddress,
    name: 'saleStateMap',
    params: ['1']
  }

  const callMaxSupplyCore = {
    address: nexItemsAddress,
    name: 'maxSupplyMap',
    params: ['1']
  }

  const callUriCore = {
    address: nexItemsAddress,
    name: 'uri',
    params: ['1']
  }


  const calls = [
    callTotalSupply,
    callSaleState,
    callMaxSupply,
    callUri,
    callTotalSupplyCore,
    callSaleStateCore,
    callMaxSupplyCore,
    callUriCore
  ]


  const rawNexItemsAmounts = await multicall<BigNumber[]>(nexItemsABI, calls)

  const parsedAmounts = rawNexItemsAmounts.map((nexItemsBalance, index) => {
      if(index === 3 || index ===7){
          return nexItemsBalance.toString();
      }
      return new BigNumber(nexItemsBalance).toJSON();
  })

  return {
    keycard: {
      totalSupply: parsedAmounts[0],
      saleState: parsedAmounts[1],
      maxSupply: parsedAmounts[2],
      uri: parsedAmounts[3]
    },
    core: {
      totalSupply: parsedAmounts[4],
      saleState: parsedAmounts[5],
      maxSupply: parsedAmounts[6],
      uri: parsedAmounts[7]
    }
  }
}

