import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import autoBossBattlesABI from 'config/abi/autoBossBattles.json'
import multicall from 'utils/multicall'
import { getAutoBossBattlesAddress } from 'utils/addressHelpers'

export const fetchPublicAutoBossBattles = async () => {
  const autoBossBattlesAddress = getAutoBossBattlesAddress()
  
  const callBattleCounter = {
    address: autoBossBattlesAddress,
    name: 'battleCounter',
    params: []
  }

  const callDropsLength = {
    address: autoBossBattlesAddress,
    name: 'getDropsLength',
    params: []
  }

  const calls = [
    callBattleCounter,
    callDropsLength
  ]


  const rawAutoBossBattlesAmounts = await multicall<BigNumber[]>(autoBossBattlesABI, calls)

  const parsedAmounts = rawAutoBossBattlesAmounts.map((autoBossBattlesBalance) => {
    return new BigNumber(autoBossBattlesBalance).toJSON()
  })

  const dropsLength = Number(parsedAmounts[1]);

  let drops = [];
  for (let i = 0; i < dropsLength; i++) {
    // Check if dataStick ID exists by checking if its totalSupply is greater than 0
    const callDrops = {
      address: autoBossBattlesAddress,
      name: 'drops',
      params: [i]
    }

    const dropsCall = await multicall<any>(autoBossBattlesABI, [callDrops])
    const drop = dropsCall.map((dropRes) => {
      if(dropRes.amount){
        dropRes = {
          amount: dropRes.dropType == 0 ? (Number(dropRes.amount.toString())/10**18).toString() : Number(dropRes.amount.toString()),
          contractAddress: dropRes.contractAddress,
          requiresKeycard: Boolean(dropRes.requiresKeycard),
          dropWeight: Number(dropRes.dropWeight.toString()),
          id: Number(dropRes.id.toString())
        }
        return dropRes
      }
    })
    drops.push(drop[0]);
  }

  return {
    battleCounter: parsedAmounts[0],
    drops
  }
}
