import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import coreManagerABI from 'config/abi/coreManager.json'
import multicall from 'utils/multicall'
import { getCoreManagerAddress } from 'utils/addressHelpers'


export const fetchUserCoreManager = async (account: string) => {
  const coreManagerAddress = getCoreManagerAddress()

  const callGetCoreData = {
    address: coreManagerAddress,
    name: 'getCoreData',
    params: [account]
  }

  const callGetDataStickData = {
    address: coreManagerAddress,
    name: 'getDataStickData',
    params: [account]
  }

  const calls = [
    callGetCoreData,
    callGetDataStickData
  ]

  const rawCoreManagerData = await multicall<BigNumber[]>(coreManagerABI, calls)

  const parsedJSON = rawCoreManagerData.map((coreData) => {
    return coreData
  })

  return {
    tierEquipped: parsedJSON[0][0].toNumber(),
    dataStickIds: parsedJSON[0][1].map((bn: BigNumber) => bn.toNumber()),
    currentUses: parsedJSON[1][0].map((bn: BigNumber) => bn.toNumber())
  };
}
