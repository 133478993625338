import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchAutoBossBattlesPublicDataAsync, fetchAutoBossBattlesUserDataAsync } from '.'
import { DeserializedAutoBossBattles, DeserializedAutoBossBattlesUserData, DeserializedAutoBossBattlesState, SerializedAutoBossBattles, SerializedAutoBossBattlesUserData, State } from '../types'

const deserializeAutoBossBattlesData = (data: SerializedAutoBossBattles): DeserializedAutoBossBattles => {
  return {
    battleCounter: data.battleCounter ? new BigNumber(data.battleCounter) : BIG_ZERO,
    drops: data.drops
  }
}

const deserializeAutoBossBattlesUserData = (data: SerializedAutoBossBattlesUserData): DeserializedAutoBossBattlesUserData => {
  return {
      battle: {
        bossId: data.battle.bossId ? Number(data.battle.bossId) : BIG_ZERO,
        startTime: data.battle.startTime ? Number(data.battle.startTime) : BIG_ZERO,
        battleDuration: data.battle.battleDuration ? Number(data.battle.battleDuration) : BIG_ZERO,
        feeAmount: data.battle.feeAmount ? Number(data.battle.feeAmount) : BIG_ZERO
      },
      kills: data.kills ? Number(data.kills) : BIG_ZERO,
      deaths: data.kills ? Number(data.kills) : BIG_ZERO,
      // dailyRewardsEarned: data.dailyRewardsEarned ? Number(data.dailyRewardsEarned) : BIG_ZERO,
      // lastTimeEarned: data.lastTimeEarned ? Number(data.lastTimeEarned) : BIG_ZERO,
      // totalXpEarned: data.totalXpEarned ? Number(data.totalXpEarned) : BIG_ZERO,
    }
}

export const useAutoBossBattles = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchAutoBossBattlesPublicDataAsync(''))
    dispatch(fetchAutoBossBattlesUserDataAsync(account))
  }, [account, dispatch])
}

export const useAutoBossBattlesData = (): DeserializedAutoBossBattles => {
  const autoBossBattlesData = useSelector((state: State) => state.autoBossBattles.data)

  if(!autoBossBattlesData){
    return {
      battleCounter: new BigNumber('0'), 
      drops: []   
    }
  }

  const deserializedAutoBossBattlesData: DeserializedAutoBossBattles = deserializeAutoBossBattlesData(autoBossBattlesData)

  return deserializeAutoBossBattlesData(autoBossBattlesData)
}

export const useAutoBossBattlesUserData = (): DeserializedAutoBossBattlesUserData => {
  const autoBossBattlesUserData = useSelector((state: State) => state.autoBossBattles.userData)

  if(!autoBossBattlesUserData){
    return {
      battle: {
        bossId: new BigNumber('0'),
        startTime: new BigNumber('0'),
        battleDuration: new BigNumber('0'),
        feeAmount: new BigNumber('0')
      },
      kills: new BigNumber('0'),
      deaths: new BigNumber('0'),
      // dailyRewardsEarned: new BigNumber('0'),
      // lastTimeEarned: new BigNumber('0'),
      // totalXpEarned: new BigNumber('0')
    }
  }

  const deserializedAutoBossBattlesUserData: DeserializedAutoBossBattlesUserData = deserializeAutoBossBattlesUserData(autoBossBattlesUserData)

  return deserializeAutoBossBattlesUserData(autoBossBattlesUserData)
}
