import { Address } from '../types'

export enum NexCollectionKey {
  GLITCH = 'glitch',
}

export type NexCollection = {
  name: string
  description?: string
  slug: string
  address: Address
}

export type NexCollections = {
  [key in NexCollectionKey]: NexCollection
}
